<template>
  <moe-page title="标签管理">
    <!-- 筛选信息 -->
    <moe-inquire @search="userTagSearch">
      <el-form-item label="标签名称">
        <el-input v-model.trim="userTagParams.name" placeholder="输入标签名称" maxlength="100" clearable />
      </el-form-item>
    </moe-inquire>

    <moe-table ref="userTagTableRef" url="/tag/list" :params="userTagParams">
      <el-button slot="tool" type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/userManage/tagsManage/add')">新建标签</el-button>
      <el-table-column prop="name" label="标签名称" min-width="150" :show-overflow-tooltip="true" />
      <el-table-column prop="desc" label="标签描述" min-width="150" :show-overflow-tooltip="true" />
      <el-table-column prop="count" label="人数" min-width="80" />
      <el-table-column prop="createTime" label="创建时间" min-width="150" :formatter="formatterCreateTime" />
      <el-table-column prop="userUpdateTime" label="更新时间" min-width="150" :formatter="formatterUserUpdateTime" />
      <el-table-column label="操作" width="300" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }" v-if="row.type === 'CUSTOM'">
          <el-button type="success" size="mini" icon="el-icon-document" @click="handleUpdate(row)">更新</el-button>
          <el-button type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/userManage/tagsManage/add`, { id: row.id })">编辑</el-button>
          <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'UserManageTagsManageList',
  data() {
    return {
      userTagParams: {
        pageNum: 1,
        pageSize: 10,
        name: '',
      }
    }
  },
  methods: {
    formatterCreateTime({ createTime }) {
      return this.$moe_time.getTimeStamp(createTime);
    },
    formatterUserUpdateTime({ userUpdateTime }) {
      return this.$moe_time.getTimeStamp(userUpdateTime);
    },
    userTagSearch(isSearch) {
      if (!isSearch) {
        this.userTagParams = {
          pageNum: 1,
          pageSize: 10,
          name: '',
        }
      }
      //刷新用户表格
      this.$refs['userTagTableRef'].searchData();
    },
    async handleUpdate({ name, id }) {
      this.$confirm(`
      <div>您确定要更新当前选项"${name}"吗？</div>
      <div>更新标签可能耗时较长，请耐心等待。</div>
      `,  '系统提示', {
        dangerouslyUseHTMLString: true,
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = instance.cancelButtonLoading = true;
            instance.confirmButtonText = instance.cancelButtonText = '请稍候...';
            const { code, message } = await this.$moe_api.USER_API.updateListUserTag({ id })
            if (code === 200) {
              done();
              this.$moe_msg.success('更新成功');
              this.userTagSearch(false);
            } else {
              done();
              if (message) {
                this.$moe_msg.error(message);
              }
            }
            instance.confirmButtonLoading = instance.cancelButtonLoading = false;
            instance.confirmButtonText = '确定';
            instance.cancelButtonText = '取消';
          } else if (action === 'cancel') {
            done();
          }
        }
      })
    },
    async handleDelete({ name, id }) {
      this.$moe_layer.confirm(`您确定要删除当前选项"${name}"吗？`, async() => {
        const { code, message } = await this.$moe_api.USER_API.deleteUserTag({ id })
        if (code == 200) {
          this.$moe_msg.success('删除成功');
          this.userTagSearch(false);
        } else {
          this.$moe_msg.error(message);
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>

</style>